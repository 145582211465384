html {
  height: 100%;
  overflow-y: auto;
  overscroll-behavior: contain;
  /* font-size: 10px; */
}

body {
  margin: 0;
  height: 100%;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overscroll-behavior: contain;
  touch-action: none;

}

#root {
  height: 100%;
}
